export enum PRIMARY_COLORS {
  DARK_GRAY = "#3B3B3B",
  GHOST_WHITE = "#F5FCFF",
  PATTENS_BLUE = "#D5E2EA",
  CERULEAN = "#00649C",
  GREY_CHATEAU = "#98A0A7",
}

export const barColors = [
  "#0085D0",
  "#E74C3C",
  "#1ABC9C",
  "#F1C40F",
  "#F2E3A6",
  "#E95E7F",
  "#A3E4D7",
  "#41E1EB",
  "#E600A2",
  "#59C1FC",
  "#CC0001",
  "#EDBB99",
  "#ADDD23",
  "#E1EC6A",
  "#ABA62A",
  "#64AA54",
  "#9A7D0A",
  "#7DCEA0",
  "#36DA51",
  "#196F3D",
  "#D2B4DE",
  "#B735BA",
  "#8E44AD",
  "#78281F",
  "#ABB2B9",
  "#2C3E50",
  "#C85B89",
  "#8070C0",
  "#85E4E8",
  "#4A6164",
  "#942D25",
  "#0233BA",
  "#2079E9",
  "#981A11",
  "#F4A48C",
  "#EBF3A0",
  "#2E9111",
  "#5DF7E5",
  "#087FAA",
  "#EA71C1",
  "#0C2CBD",
  "#F6F086",
  "#D12367",
  "#55F030",
  "#873219",
  "#401714",
  "#E0470D",
  "#5B21C5",
  "#97189E",
  "#451213",
  "#0CD757",
  "#90AD5E",
  "#A5DACD",
  "#2CF2B6",
  "#430F0A",
  "#A82E78",
  "#327D3A",
  "#75CD60",
  "#B7B0E4",
  "#B60492",
  "#6E69B8",
  "#42EEEA",
  "#598358",
  "#572766",
  "#89A66B",
  "#2991F2",
  "#7CF754",
  "#B557A8",
  "#8D05D9",
  "#C09104",
  "#62CC41",
  "#15ECF8",
  "#528858",
  "#7FB4E4",
  "#B553E7",
  "#FAA1EE",
  "#EA23AC",
  "#DB75F9",
  "#7C3D12",
  "#8C0627",
  "#889A62",
  "#8F95D6",
  "#8CC28B",
  "#2A688A",
  "#4C34DA",
  "#19A814",
];
