import Modal from "components/Modal";
import { useEffect } from "react";
import "./HubSpotForm.style.css";
import config from "config";

const HubSpotForm = ({ show, setShow, hideCloseButton = false }) => {
  useEffect(() => {
    if (!show) return;

    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.async = true;

    script.onload = () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: "7697776",
          formId: "0b1578ca-e20a-45eb-893e-007f6d54d68a",
          target: "#hubspot-form",
          cssClass: "hubspot-form",
          onFormReady: function ($form) {
            const verticalField = $form.find('input[name="vertical"]');
            if (verticalField.length) {
              verticalField[0].value = config.PLATFORM_NAME;
            }
          },
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [show]);

  if (!show) return null;

  return (
    <Modal
      isShowing={true}
      showHeader={true}
      showTitle={true}
      title="Contact Us"
      onHide={hideCloseButton ? undefined : () => {
        setShow(false);
      }}

      classNames={{
        borderLine: "hidden",
        modalHeader:
          "grid grid-cols-2 pt-[18px] px-[24px] text-primary",
        closeButtonContainer: "justify-self-end",
        childrenContainer: "p-2",
        popup: "max-w-[700px]",
      }}
    >
      <div className="text-center">
        <p className="text-primary pb-6 mt-[-12px]">Reach out to us with your questions, ideas, <br />or feedback — our team is here to help!</p>
        <div id="hubspot-form"></div>
      </div>
    </Modal>
  );
};

export default HubSpotForm;
