import React, { memo, useState } from "react";
import CrownWhiteIcon from "assets/images/crown-white-icon.svg";
import { Button } from "components/Button";
import classnames from "classnames";
import { useOutletContext } from "react-router";
import { IOutletAuth } from "types";
import HubSpotForm from "components/HubSpotForm";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

function PremiumButton({ className }: IProps) {
  const [showForm, setShowForm] = useState(false);

  const authContext = useOutletContext<IOutletAuth>();
  const isAuth = authContext.isAuth;
  const classes = classnames(
    { "bg-[#28C18A] hover:bg-[#28C18A]": !isAuth },
    className,
    "cursor-pointer w-[160px] h-[38px] py-[12px] justify-center font-bold rounded-[4px] text-[12px] items-center text-center whitespace-no-wrap align-middle select-none text-base leading-normal text-[#FFFFFF] bg-[#28C18A] hover:text-white hover:bg-[#31414E] hover:border-primary align-self-center opacity-100"
  );

  return (
    <>
      <Button
        customPaddings
        className={classes}
        onClick={() => {
          setShowForm(true)
        }}
      >
        <img src={CrownWhiteIcon} alt="CrownWhiteIcon" className="mr-2" />
        Premium Access
      </Button>{" "}

      <HubSpotForm setShow={setShowForm} show={showForm} />
    </>
  );
}
export default memo(PremiumButton);
